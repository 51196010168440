<template>
    <div class="modal-global">
        <div class="left-side p-4">
            <div class="left-side-container">
                <h4 class="mt-4 d-flex justify-content-center align-items-center font-weight-bold" style="margin-bottom: 1em; font-size: 2rem;">Formule choisie</h4>
                <b-card class="shadow-lg mb-0" style="border-radius: 25px; border: 1px solid gray; position: relative">
                    <div class="selected-plan d-flex justify-content-center align-items-center ">
                        <i class="fa fa-check text-white fa-lg"  aria-hidden="true"></i>
                    </div>
                    <!--composant souscrit-->
                    <div  style="border-radius: 25px; padding-bottom: 2em">
                        <div class="plan-price pl-0">
                            <!--<h3 class="fs-1">{{ formule.type }}</h3>-->
                            <h3 class="fs-1 text-muted">{{ formule.type }}</h3>
                            <!--<h4><span class="value">{{ formule.prix }}</span><span class="period"> / {{$t('data.utilisation_par_an')}}</span></h4>-->
                            <h4><span class="value font-weight-bold">{{ formule.prix }}</span><span class="period"> / {{$t('data.utilisation_par_an')}}</span></h4>
                        </div>
                        <div class="plan-features" style="width: 80%; border-top: 1px solid gray; margin-left: 1.5em;">

                            <b-list-group class="mb-2 ml-0">
                                <!--<b-list-group-item v-for="(acces,i) in formule.access" :key="i" class="d-flex justify-content-between align-items-center borderless">
                                    <b-badge variant="secondary" pill><i class="fa fa-check" aria-hidden="true"></i></b-badge>
                                   <span  class="text-muted">{{ acces }}</span>
                                </b-list-group-item>-->
                                <b-list-group-item v-for="(acces,i) in formule.access" :key="i" class="d-flex justify-content-between align-items-center borderless pt-2 ml-0 pl-0">
                                    <b-badge variant="secondary" pill><i class="fa fa-check" aria-hidden="true"></i></b-badge>
                                    <span  class="text-muted ml-2" style="font-size: 1rem;">{{ acces }}</span>
                                </b-list-group-item>
                            </b-list-group>
                           
                        </div>
                    </div>
                </b-card>
                <p class="float-right mt-1" style="font-size:1.2rem"><b-link @click="() => { $emit('closePaymentModal')}">changer de formule</b-link> </p>
            </div>
        </div>
        <div class="right-side">
            <div class="container-fluid">
                <h2 class="font-weight-bold" style="margin: 0 auto">Méthodes de paiement</h2> 
            </div>
            <div class=" p-4">
                <div class="help-block font-weight-bold mt-2">
                     <p style="font-size: 1.8em">{{$t("data.souscrire_au_forfait_moyen_paiement_description")}}	</p>
                    <div class="d-flex justify-content-between align-items-center">
                       
                        <div class="rounded overflow-hidden" style="width: 100px; height: 100px;">
                            <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/mtn_mobileMoney.png`" alt="Amex">
                        </div>
                        
                        <div class="rounded overflow-hidden" style="width: 100px; height: 100px;">
                            <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/orange_money.jpg`" width="36" alt="Paypal" border="0">
                        </div>
                
                    </div>
                 
                    <b-row style="margin-top: 20px">
                        <b-col class="mt-2 text-uppercase">{{$t('data.souscrire_au_forfait_moyen_paiement')}}</b-col>
                        <b-col cols="7">
                                <v-select style="width:80%; margin-left: 3.5em;" label="title" :options="modePaiements" v-model="modeP" :reduce="(modePaiement) => modePaiement.moyen"  @input="setPaypalButton"></v-select>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col style="width:40%">

                        </b-col>
                        <b-col style="width: 60%">
                            <div v-if="showButton" ref="paypal" id="paypal-button-id"></div>
                            <b-button v-if="!showButton" variant="danger" class="button-subscribe" style="width:95%; padding-left :2em;"><b-spinner class="mr-2" type="grow" v-if="submitted2"></b-spinner><span v-show="!submitted2">Payer</span></b-button>
                        </b-col>
                        
                    </b-row>	
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const php  = require ( 'phpjs' ); 
     export default {
        name: "modal",
       data:()=>({
           submitted2:false,
           showButton:false,
           loaded:false,
           showOverlay:false,
           disabled:false,
           formuleType:'',
           detailsPaiements:'',
           typeF:'gratuit',
           modeP:'mntMoMo',
           modePaiements: [
            {
            title: 'Paypal',
            icon: 'fa fa-database',
            moyen: 'paypal'
            },
            {
            title: 'MTN Mobile money',
            icon: 'fa fa-book',
            moyen: 'mntMoMo'
            },
            {
            title: '  Orange Money',
            icon: 'fas fa-dollar-sign',
            moyen: 'om'
            },
           ],

       }),
     
        
        watch: {
        },
        props: {
            formule: { type: Object, required: true }
        },
        methods:{
            setPaypalButton(){
                console.log('ecoute changement de valeur sélectionnée', this.modeP);
                if(this.modeP=='paypal'){
                    console.log('prix', this.formule.prix)
                    this.showButton=true;
                    this.toggleStatus();
                }else{
                    this.showButton=false
                }
            },

            toggleStatus() {
                this.showOverlay=true

                const script = document.createElement("script");
                script.src ="https://www.paypal.com/sdk/js?client-id=AWll2noucpUtJT86ULiJnfJoXRSTXMNOCdg64upWlQrGR5sizlQsVcoaKJANLeobax6zT3w0C4DgCgrX";
                script.addEventListener("load", this.setLoaded);
                document.body.appendChild(script);

            },
            setLoaded: function() {
                this.loaded = true;
               
                window.paypal
                    .Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                        purchase_units: [
                            {
                            description:"test",
                            amount: {
                                currency_code: "USD",
                                value: this.formule.prix
                            }
                            }
                        ]
                        });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        this.paidFor = true;
                        this.detailsPaiements=order
                        console.log(order);
                        this.suscribeToFormule();
                    },
                    onError: err => {
                        console.log(err);
                    }
                    })
                    .render(this.$refs.paypal);
                    this.showOverlay=false
            }, 
            suscribeToFormule(){

                this.showOverlay=true;
                let data={
                    idFormule:this.formule.idFormule,
                    login: storage.get('user-email'),
                    detailsPaiements:this.detailsPaiements
                }

                axios.patch("entreprises/set-formule",data).then(response =>{
                    const user = this.$store.state.user
                    user.entreprises = user.entreprises.map(elt => {
                        if (elt.idEntreprise == storage.get('selected_enterprise')) {
                            elt.idFormule = this.formule.idFormule
                        }
                        return elt
                    })
                    this.$store.dispatch('user', user)
                    storage.set('user', user)
                    
                    this.showOverlay=false;
                    this.showMsgBoxTwo();
                    //storage.remove('user-email')
                })
                .catch(error => {
                    this.showOverlay=false;
                    return App.notifyError(error.message)
                });
            },
            showMsgBoxTwo() {
                this.boxTwo = ''
                this.$bvModal.msgBoxOk('Souscription éffectuée avec succès !!!', {
                title: 'SUCCESS',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
                })
                .then(value => {
                    this.boxTwo = value
                    this.$router.push({name: 'home'});
                    console.log("entree fin execution !!!");
                })
                .catch(err => {
                    // An error occurred
                })
       
            }
       
           

            
        },
        mounted(){

        },
    }
</script>

<style scoped>
    .modal-global {
        width: 70em;
        height: 50em;
        background: white;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
    }
    .left-side{
        width: 38%;
    }
    .left-side-container{
        padding-right:1em;
        border-right: 1px solid gray;
    }
    .right-side{
        width: 56%;
    }
    .button-subscribe{
     height: 45px; 
     width: auto; 
     border-radius: 5px; 
     font-weight: bold; 
     border: none;
     transition: .3s ease;
     box-shadow: 0 5px 12px rgba(59, 41, 41, 0.089);
    }
    .button-subscribe:hover{
      transform: scale(1.03);
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.199);
    }
    .selected-plan{
        bottom:97%;
        position:absolute;
        right:25%;
        width:2em;
        height: 2em;
        border-radius: 50%;
        background-color:red;
    }
    .plan-features {
        background: #fff;
        border-top: none;
        padding: 12px 0 0;
    }
    .borderless{
        border:none;
    }
</style>