<template>
  <div class="payment-main">
    <div class="left-part">
      <div class="content">
        <div class="content-child">
          <div class="txt">
            <img
              src="@/assets/img/maisonier.svg"
              height="50"
              width="50"
              alt="logo maisonier"
              class="logo-brand"
            />
            <span class="title">Votre partenaire idéal pour une gestion immobilière simple, efficace et <b>rentable.</b></span>
            <p>
              Maisonier Gestion repousse les limites pour vous garantir une croissance exponentielle de votre activité.
            </p>
            <div>
              <span>
                Vous êtes une entreprise de grande envergure ?
              </span>
              <a href="#">
                Contactez-nous
              </a> <br>
              
              <b @click="back()" style="cursor:pointer;text-decoration:underline;">
                Retour
              </b>
            </div>
          </div>
          <div class="img">
            <img
              src="@/assets/img/maisonier-upgrade.svg"
              alt="Maisonier upgrade"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="right-part">
      <span class="title">
        Choisir un abonnement.
      </span>
      <p class="desc">
        Votre partenaire idéal pour une gestion immobilière simple, efficace et rentable.
      </p>
      <ul class="pricing-container">
        <li
          v-for="(formule, cle) in formulesPaiement"
          :key="cle"
          class="pricing-item"
          @click="
              ismodalOpen = true;
              selectedPlan = formule;
            "
        >
          <div class="pricing-header">
            <span class="pricing-title">
              {{ formule.nomFormule }}
            </span>
            <span class="pricing-price">
              <b>{{ priceFormat(formule.prix) }} FCFA</b> / l'année
            </span> 
          </div>
          <div class="budget">
            <span>
              Pours les entreprises immobilières dont le chiffre d'affaire mensuel est en dessous de <b>{{ priceFormat(formule.budget) }} FCFA</b>  
            </span>
          </div>
          <ul class="features-container">
            <li
              class="feature"
            >
              <i class="fa fa-check"></i>
              <span>
                {{ formule.nbrUtilisateur }} utilisateurs max
              </span>
            </li>
            <li
              v-for="(advantage, cle2) in formule.caracteristiques"
              :key="cle2"
              class="feature"
            >
              <i class="fa fa-check"></i>
              <span>
                {{ advantage }}
              </span>
            </li>
          </ul>
          <button
          >
            Activer l'abonnement
          </button>
        </li>
        <li
          class="pricing-item"
        >
          <div class="pricing-header">
            <span class="pricing-title">
              Diamond
            </span>
            <span class="pricing-price">
              <b>------</b> / l'année
            </span>
          </div>
          <div class="budget">
            <span>
              Vous êtes une entreprise de grande envergure, <b>contactez-nous.</b>
            </span>
          </div>
          <div class="features-container">
            <span style="font-size:.9rem;">
              Une solution 360º conçue sur mesure par rapport à vos attentes. 
            </span>
          </div>
          <button
          >
            Contactez-nous
          </button>
        </li>
      </ul>
    </div>
    <div class="package-detail" :class="{ visible: ismodalOpen }">
      <div class="close-modal" @click="
            ismodalOpen = false;
            paymentOperator = null;
          ">
        <img
          src="@/assets/img/close.png"
          alt="close modal icon"
        />
      </div>
      <div class="moda">
        <div class="moda-left">
          <span class="title">
            Abonnement selectionné
          </span>
          <div class="current-plan">
            <li class="pricing-item">
              <div class="pricing-header">
                <span class="pricing-title">
                  {{ selectedPlan.type }}
                </span>
                <span class="pricing-price">
                  <b>{{ priceFormat(selectedPlan.prix) }} FCFA</b> / l'année
                  {{ selectedPlan.prix }}
                </span>
              </div>
              <ul class="features-container">
                <li
              class="feature"
            >
              <i class="fa fa-check"></i>
              <span>
                Chiffre d'affaire mensuel max {{ priceFormat(selectedPlan.budget) }} FCFA
              </span>
            </li>
            <li
              class="feature"
            >
              <i class="fa fa-check"></i>
              <span>
                {{ selectedPlan.nbrUtilisateur }} utilisateurs max
              </span>
            </li>
                <li
                  v-for="(adv, cle3) in selectedPlan.caracteristiques"
                  :key="cle3"
                  class="feature"
                >
                  <i class="fa fa-check"></i>
                  <span>
                    {{ adv }}
                  </span>
                </li>
              </ul>
            </li>
          </div>
          <div class="change-plan">
            <span class="change-txt" @click="alternativeOpen = true"
              >Changer de plan</span
            >
            <div class="alternatives" :class="{ visible: alternativeOpen }">
              <button
                v-for="(alternative, cle4) in formulesPaiement"
                :key="cle4"
                @click="
                  selectedPlan = alternative;
                  alternativeOpen = false;
                "
              >
                <span>
                  {{ alternative.type }}
                </span>
                <span>
                  <b>{{  priceFormat(alternative.prix) }}</b> FCFA / l'année
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="barre"></div>
        <div class="moda-right">
          <div v-if="selectedPlan.prix != 0">
            <div class="moda-right-header">
              <span class="title">Nos moyens de paiement</span>
              <span class="desc">
                Choisissez le moyen de paiement qui vous convient parmi ces deux
                options.
              </span>
            </div>
            <div class="payment-container">
              <img
                src="@/assets/img/orange1.png"
                @click="subscribe('om')"
                alt="orange money"
              />
              <img
                src="@/assets/img/mtn1.png"
                @click="subscribe('momo')"
                alt="mtn mobile money"
              />
            </div>
            <div class="other-issue">
              <span>Vous souhaitez payez autrement ? <b>Contactez-nous</b></span>
            </div>
          </div>
          <div v-else>
            <div class="moda-right-header">
              <span class="title">Commencer avec le mode gratuit</span>
              <span class="desc">
                Avec ce plan vous pourrez explorer un grand nombre de fonctionalités que nous mettons à votre disposition pour la gestion de vos bien imobilier.
              </span>
            </div>
            <div class="payment-container" style="display: flex;justify-content: center; align-items: center;">
              <span
                style="background-color: #212121;font-size: large;padding: 2%; color: white;cursor: pointer;"
                @click="subscribe('free')"
              >
                Activer le mode gratuit
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      size="sm"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      id="modal-load"
    >
      <div
        class="py-5 w-100 d-flex flex-column justify-content-center align-items-center"
      >
        <b-spinner />
        <p>Redirection en cours...</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
const php = require("phpjs");
import PaymentForm from "@/components/templates/Modal.vue";
export default {
  name: "subscribe",
  components: {
    PaymentForm,
  },
  data: () => ({
    ismodalOpen: false,
    alternativeOpen: false,
    paymentOperator: null,
    formule: null,
    selectedPlan: {},
    formulesPaiement: [],
    modePaiements: [
      {
        title: "Paypal",
        icon: "fa fa-database",
        moyen: "paypal",
      },
      {
        title: "MTN Mobile money",
        icon: "fa fa-book",
        moyen: "mntMoMo",
      },
      {
        title: "  Orange Money",
        icon: "fas fa-dollar-sign",
        moyen: "om",
      },
    ],
    modeP: "mntMoMo",
    frequencePaiement: "",
    options: [
      { montant: "12000", nom: "12 000 F/ 3 mois" },
      { montant: "40000", nom: "40 000 F/ An" },
    ],
    /**data boite de dialogue */
    boxTwo: "",
    domainName: "https://app.maisonier.com"
  }),
  mounted() {
    this.getFormulesPaiement();

    const { payment_status, entreprise } = new Proxy(new URLSearchParams(window.location.search),
      {
        get: (searchParams, prop) => searchParams.get(prop),
      }
    );
    if (
      payment_status != undefined &&
      payment_status.toUpperCase() != "SUCCESS"
    ) {
      App.alertError("Votre paiement n'a pas abouti. Veuillez réessayer", {callback: () => {
        window.history.replaceState({}, 'Maisonier Gestion', window.location.href.split('?')[0])
      }});
    }
  },

  methods: {
    
    priceFormat(number) {
      return String(number).replace(/(.)(?=(\d{3})+$)/g, "$1,");
    },
    back(){
      this.$router.push({ name: "select-entreprise" })
    },
    getFormulesPaiement() {
			axios.get('constants/formules').then(response => response.result || []).then(formules => {
				this.formulesPaiement = formules.filter(elt => !elt.isBusiness).map(formule => {
					formule.type = php.ucfirst(formule.nomFormule)
          formule.access = (formule.caracteristiques || []).map(elt => php.ucfirst(elt))
					return formule
				})
			})
		},

    subscribe(operator) {
      if (php.empty(this.selectedPlan)) {
        return App.alertError("Veuillez sélectionner un plan d'abonnement0");
      }
      if (php.empty(operator)) {
        operator = this.paymentOperator;
      }
      console.log("operator", operator)
      if (!["om", "momo", "free"].includes(operator)) {
        return App.alertError("Veuillez sélectionner le mode de paiement1");
      }
      const { host, protocol } = window.location;
        const data = {
          operator,
          idFormule: this.selectedPlan.idFormule,
          idEntreprise: storage.get("selected_enterprise"),
          // return_url: this.domainName + `/check-souscription`,
          return_url: `${protocol.replace(
            /:(\/\/)?/,
            ""
          )}://${host}/check-souscription`,
        };

      if (operator === "momo") {
        localStorage.setItem("idFormule", data.idFormule);
        localStorage.setItem("idEntreprise", data.idEntreprise);
        localStorage.setItem("return_url", data.return_url);
        localStorage.setItem("formulePrice", this.selectedPlan.prix);
        localStorage.setItem("formuleType", this.selectedPlan.type);
        this.$router.push({name: "subscribe/momo"});
      }
      else {
        this.$bvModal.show("modal-load");
        axios
          .post("entreprises/subscribe-plan", data)
          .then((response) => {
            window.location.href =
              response.message.toLowerCase() == "paiement"
                ? response.result
                : this.$router.options.base;
          })
          .catch((error) => {
            App.alertError(
              error.message || "Une erreur s'est produite lors de l'opération"
            );
          });
      }
    },

    setLoaded: function() {
      this.loaded = true;
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: "test",
                  amount: {
                    currency_code: "USD",
                    value: this.typeF.prix,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            const order = await actions.order.capture();
            this.paidFor = true;
            this.detailsPaiements = order;
            console.log(order);
            this.suscribeToFormule();
          },
          onError: (err) => {
            console.log(err);
          },
        })
        .render(this.$refs.paypal);
      this.showOverlay = false;
    },
    setPaypalButton() {
      console.log("ecoute changement de valeur sélectionnée", this.modeP);
      if (this.modeP == "paypal") {
        this.showButton = true;
        this.toggleStatus();
      } else {
        this.showButton = false;
      }
    },
    toggleStatus() {
      this.showOverlay = true;

      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=AWll2noucpUtJT86ULiJnfJoXRSTXMNOCdg64upWlQrGR5sizlQsVcoaKJANLeobax6zT3w0C4DgCgrX";
      script.addEventListener("load", this.setLoaded);
      document.body.appendChild(script);
    },
    suscribeToFormule() {
      this.showOverlay = true;
      let data = {
        idFormule: this.typeF.idFormule,
        login: storage.get("user-email"),
        detailsPaiements: this.detailsPaiements,
      };
      axios
        .patch("entreprises/set-formule", data)
        .then((response) => {
          this.showOverlay = false;
          this.showMsgBoxTwo();
          //storage.remove('user-email')
        })
        .catch((error) => {
          console.log({ error: error });
          this.showOverlay = false;
          return App.notifyError(error.message);
        });
    },
    showMsgBoxTwo() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxOk("Souscription éffectuée avec succès !!!", {
          title: "SUCCESS",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        })
        .then((value) => {
          this.boxTwo = value;
          //this.$store.dispatch("updateUserData");
          setTimeout(() => {
            this.$router.push({ name: "login" });
            //this.$router.push({name: 'home', query: {origin: 'subscribe'}});
          }, 2000);
        })
        .catch((err) => {
          // An error occurred
        });
    },
  },
};
</script>
<style scoped>
* {
  padding: 0;
  box-sizing: border-box;
}
.payment-main {
  position: relative;
  padding: 0%;
  display: flex;
  height: 100vh;
  width: 100%;
  background: #f5f5f5;
  overflow: hidden;
}
.payment-main .left-part {
  display: flex;
  align-items: center;
  width: 20%;
  height: 100%;
  /* background: teal; */
  z-index: 3;
}
.payment-main .left-part .content {
  /* padding: 1%; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background: white;
  /* background: #F5365C; */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08); */
}
.left-part .content .content-child {
  padding: 10% 7% 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  /* border-radius: 15px; */
  background: #191c22;
}
.logo-brand {
  margin-bottom: 30px;
}
.content-child .txt {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.content-child .txt .title {
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
}
.content-child .txt .title b{
  color: #f5365c;
}
.content-child .txt p {
  margin-top: 1.5rem;
  font-size: 0.95rem;
  color: white;
}
.content-child .txt div {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 0.95rem;
  color: white;
}
.content-child .txt div a {
  margin-top: 0.75rem;
  font-size: 0.95rem;
  font-weight: bold;
  text-decoration: underline;
  color: white;
}
.payment-main .right-part {
  padding: 2% 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: auto;
  z-index: 5;
  overflow-y: auto;
}
.payment-main .right-part .title {
  margin-bottom: 0.8rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform:uppercase;
}
.right-part .desc {
  width: 60%;
  text-align: center;
  color: #747474;
  line-height: 1.5rem;
}
.payment-main .right-part .pricing-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(5, calc( 25% - 5px )) ;
  grid-column-gap: 10px;
  width: 100%;
  height: 70%;
  /* background:teal; */
}
.pricing-item {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: pointer;
  /* display:none; */
}
.pricing-item:nth-child(2) {
  background: #191c22;
}
.pricing-item:hover {
  transform:scale(1.02);
  box-shadow: 0 12px 28px rgba(0, 0, 0, 0.2);
}
.pricing-item .pricing-header {
  display: flex;
  flex-direction: column;
  height: 15%;
  width: 100%;
  /* border-bottom: 1px solid #efefef; */
}
.pricing-item .budget{
  margin-top: 10px;
  padding: 15px 0;
  border-top:1px solid #efefef;
  border-bottom:1px solid #efefef;
}
.pricing-item .budget span{
  font-size:.95rem;
  text-align:justify;
}
.pricing-header .pricing-title {
  font-size: 1.3rem;
  /* font-weight: bold; */
  color: #747474;
}
.pricing-header .pricing-price {
  font-size: 0.9rem;
  color: #747474;
}
.pricing-header .pricing-price b {
  font-size: 1.3rem;
  font-weight: bold;
  color: #191c22;
}
.pricing-item button {
  height: 50px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #efefef;
  color: #747474;
  background: transparent;
  font-size: 1rem;
}
.pricing-item:nth-child(2) .pricing-header .pricing-title,
.pricing-item:nth-child(2) .pricing-header .pricing-price b,
.pricing-item:nth-child(2) button {
  color: rgb(241, 241, 241);
}
.pricing-item:nth-child(2) .pricing-header .pricing-price {
  color: white;
}

.pricing-item:nth-child(2) .budget{
  border-top:1px solid #616161;
  border-bottom:1px solid #616161;
}
.pricing-item:nth-child(2) .budget span{
  color: rgb(241, 241, 241);
}
.pricing-item button.active {
  color: rgb(241, 241, 241);
  border-color: rgb(230, 230, 230);
}
.pricing-item .features-container {
  padding-top: 1.5rem;
  height: calc(100% - 45%);
  width: 100%;
}
.features-container .feature {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
}
.feature i {
  margin-right: 10px;
  color: #747474;
  font-size: 0.8rem;
}
.feature span {
  font-size: .95rem;
  color: #747474;
}
.feature i.active {
  color: rgb(241, 241, 241);
}
.feature span.active {
  color: rgb(241, 241, 241);
}
.right-part .action-call {
  margin-top: 5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.pricing-item:nth-child(2) .features-container .feature i,
.pricing-item:nth-child(2) .features-container .feature span {
  color: rgb(241, 241, 241);
}
.action-call span {
  font-size: 0.85rem;
}
.action-call span a {
  font-size: 0.85rem;
  font-weight: bold;
  color: #f5365c;
}
.package-detail {
  position: fixed;
  top: 130vh;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #00000023;
  z-index: 10;
  transition: 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.package-detail.visible {
  top: 0;
}
.package-detail .close-modal {
  padding: 0 5%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 10%;
  width: 100%;
  cursor: pointer;
}
.package-detail .close-modal img {
  cursor: pointer;
  width: 20px;
}
.package-detail .moda {
  padding: 5%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90%;
  background: white;
}
.moda .moda-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 25%;
}
.moda-left .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.moda-left .current-plan {
  margin: 20px 0;
  width: 100%;
  height: 75%;
}
.moda-left .current-plan .pricing-item {
  padding: 5%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.05);
  transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  cursor: default;
  border: 1px solid #dddddd;
}
.moda-left .change-plan {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.change-plan .alternatives {
  padding: 10px 20px;
  position: absolute;
  bottom: 0;
  width: 230px;
  height: auto;
  border-radius: 15px;
  background: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid #dddddd;
  display: none;
}
.change-plan .alternatives.visible {
  display: block;
}
.change-plan .change-txt {
  cursor: pointer;
}
.alternatives button {
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  transition: 0.3s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-color: #dddddd;
}
.alternatives button:hover {
  border-bottom: 1px solid #dddddd;
}
.moda .barre {
  width: 1px;
  height: 100%;
  background: #dddddd;
}
.moda .moda-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  height: 100%;
  /* background: teal; */
}
.moda .moda-right .moda-right-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moda-right-header .title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #191c22;
}
.moda-right-header .desc {
  width: 80%;
  font-size: 1rem;
  text-align: center;
  color: #747474;
}
.moda-right .payment-container {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60%;
  width: 100%;
}
.payment-container img {
  cursor: pointer;
  height: 90%;
  max-height: 400px;
  width: 48%;
  max-width: 666px;
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.payment-container img:hover {
  transform: scale(1.05);
}
.payment-container .other-issue {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.other-issue span {
  font-size: 0.9rem;
}
  .right-part .desc{
    display:none;
  }
@media only screen and (min-width: 1300px){
  .payment-main .right-part {
    justify-content: center;
  }
}
@media only screen and (max-width: 1150px){
  
  .payment-main .left-part {
    display:none;
  }
  .payment-main .right-part {
    padding: 2% 3%;
    width: 100%;
    height: auto;
  }
  .right-part .desc {
    width: 95%;
    text-align: center;
    line-height: 1.5rem;
    display: inline-block;
  }
  .payment-main .right-part .pricing-container {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
  .pricing-item {
    padding: 15px 20px;
    height: auto;
    border-radius: 4px;
  }
}
@media only screen and (max-width: 1000px){
  .pricing-item {
    padding: 15px 20px;
    height: auto;
    border-radius: 4px;
  }
  .payment-main .right-part .pricing-container {
    grid-template-columns: repeat(2, calc( 50% - 20px )) ;
    grid-row-gap: 20px;
    grid-column-gap:20px;
  }
}
@media only screen and (max-width: 700px) {
  .payment-main {
    flex-direction: column;
    height: auto;
  }
  .payment-main .left-part {
    width: 100%;
    height: auto;
  }
  .payment-main .right-part {
    padding: 5%;
    width: 100%;
    height: auto;
  }
  .right-part .desc {
    width: 95%;
    text-align: center;
    line-height: 1.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .payment-main .right-part .pricing-container {
    grid-template-columns: 100%;
    grid-row-gap: 20px;
    grid-column-gap:0;
  }
}
</style>
